import React from 'react';
import { BlogPost, ContentSection, Reference } from '@/types/blog';
import { useArticle } from './ArticleContext';
import { ExternalLinkIcon } from '@heroicons/react/outline';

interface BlogCardProps {
  post: BlogPost;
}

const BlogCard: React.FC<BlogCardProps> = ({ post }) => {
  const { setSelectedArticle, setIsArticleOpen } = useArticle();

  const handleReadMore = () => {
    setSelectedArticle(post);
    setIsArticleOpen(true);
  };

  const renderSection = (section: ContentSection) => (
    <div key={section.title} className="mb-4">
      <h3 className="text-lg font-semibold text-gray-800 mb-2">
        {section.title}
      </h3>
      <p className="text-gray-600 whitespace-pre-line">{section.content}</p>
    </div>
  );

  const renderList = (items: string[], title: string) => (
    <div className="mb-4">
      <h3 className="text-lg font-semibold text-gray-800 mb-2">{title}</h3>
      <ul className="list-disc list-inside text-gray-600">
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );



  return (
    <article className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300">
      <img 
        src={post.imageUrl} 
        alt={post.title}
        className="w-full h-48 object-cover"
      />
      <div className="p-6">
        <div className="flex items-center mb-4">
          <span className="bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded">
            {post.category}
          </span>
          <span className="text-gray-500 text-sm ml-4">{post.date}</span>
        </div>
        <h2 className="text-xl font-bold mb-2 text-gray-800">{post.title}</h2>
        <p className="text-gray-600 mb-4 line-clamp-3">{post.excerpt}</p>
        
        {/* Preview of first section */}
        {post.sections.length > 0 && (
          <div className="mb-4 line-clamp-3">
            {renderSection(post.sections[0])}
          </div>
        )}

        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center">
            <img 
              src={post.author.avatar} 
              alt={post.author.name}
              className="w-8 h-8 rounded-full mr-2"
            />
            <span className="text-sm text-gray-700">{post.author.name}</span>
          </div>
          <button 
            onClick={handleReadMore}
            className="text-green-600 hover:text-green-800 font-medium"
          >
            Read More →
          </button>
        </div>
      </div>
    </article>
  );
};

export default BlogCard; 