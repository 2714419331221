import React, { useEffect } from 'react';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './pages/Home';
import ArticleView from './components/blog/ArticleView';
import { ArticleProvider } from './components/blog/ArticleContext';
import './App.css';

const App: React.FC = () => {
  useEffect(() => {
    document.title = 'INST 204 Final';
  }, []);

  return (
    <ArticleProvider>
      <div className="min-h-screen bg-gray-50">
        <Header />
        <main className="container mx-auto px-4 py-8">
          <Home />
        </main>
        <Footer />
        <ArticleView />
      </div>
    </ArticleProvider>
  );
};

export default App;
