import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white mt-12">
      <div className="container mx-auto px-4 py-8">
        <div className="text-center text-gray-300">
          <p>Thank you, Professor Lutters and TAs, for the great semester!</p>
        </div>
        <div className="border-t border-gray-700 mt-8 pt-4 text-center text-gray-300">
          <p>INST 204 Final Project</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 