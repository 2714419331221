import React, { createContext, useContext, useState, ReactNode } from 'react';
import { BlogPost } from '../../types/blog';

interface ArticleContextType {
  selectedArticle: BlogPost | null;
  setSelectedArticle: (article: BlogPost | null) => void;
  isArticleOpen: boolean;
  setIsArticleOpen: (isOpen: boolean) => void;
}

const ArticleContext = createContext<ArticleContextType | undefined>(undefined);

export const ArticleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedArticle, setSelectedArticle] = useState<BlogPost | null>(null);
  const [isArticleOpen, setIsArticleOpen] = useState(false);

  return (
    <ArticleContext.Provider 
      value={{ 
        selectedArticle, 
        setSelectedArticle, 
        isArticleOpen, 
        setIsArticleOpen 
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};

export const useArticle = () => {
  const context = useContext(ArticleContext);
  if (context === undefined) {
    throw new Error('useArticle must be used within an ArticleProvider');
  }
  return context;
}; 