import React from 'react';
import { Reference } from '../../types/reference';

interface ReferenceCardProps {
  reference: Reference;
}

const ReferenceCard: React.FC<ReferenceCardProps> = ({ reference }) => {
  return (
    <div className="flex items-start p-4 bg-white rounded-lg border border-gray-100">
      <div className="w-full">
        <a 
          href={reference.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-lg font-medium text-blue-600 hover:text-blue-800 hover:underline"
        >
          {reference.title}
        </a>
        
        <div className="mt-1 text-sm text-gray-600">
          {reference.authors?.join(', ')}
          {reference.publishedDate && ` • ${reference.publishedDate}`}
        </div>
        
        {reference.category && (
          <span className="inline-block mt-2 px-2 py-1 text-xs font-medium bg-blue-50 text-blue-700 rounded">
            {reference.category}
          </span>
        )}
      </div>
    </div>
  );
};

export default ReferenceCard;
