import React from 'react';
import ReferenceCard from './ReferenceCard';
import { Reference } from '../../types/reference';

const DUMMY_REFERENCES: Reference[] = [
  {
    id: 'ref1',
    title: 'Artificial Intelligence in agriculture: Growing a greener future',
    url: 'https://www.educba.com/ai-in-agriculture/',
    authors: ['EDUCBA'],
    publishedDate: '2024, May 17',
    category: 'AI in Agriculture',
    relatedPostIds: [],
  },
  {
    id: 'ref2',
    title: 'Early Crop Disease Detection with AI: Strategies for Prevention',
    url: 'https://doi.org/1004087609/1734064936884',
    authors: ['Xenonstack'],
    publishedDate: '2024',
    category: 'Disease Detection',
    relatedPostIds: [],
  },
  {
    id: 'ref3',
    title: 'Essential Plant Nutrients',
    url: 'https://www.aces.edu/blog/topics/farming/essential-plant-elements/',
    authors: ['Alabama Cooperative Extension System'],
    publishedDate: '2024, December 9',
    category: 'Plant Nutrition',
    relatedPostIds: [],
  },
  {
    id: 'ref4',
    title: 'This App Set out to Fight Pesticides. After VCs Stepped In, Now It Helps Sell Them',
    url: 'https://www.wired.com/story/plantix-pesticides-venture-capital-app/',
    authors: ['Miller, Stephen Robert'],
    publishedDate: '2024, October 24',
    category: 'Technology Impact',
    relatedPostIds: [],
  },
  {
    id: 'ref5',
    title: 'More agricultural technology resources',
    url: 'https://www.agritechtomorrow.com/story/2024/05/how-ai-is-used-for-crop-disease-diagnosis/15554/',
    authors: [],
    publishedDate: 'n.d.',
    category: 'Disease Diagnosis',
    relatedPostIds: [],
  },
  {
    id: 'ref6',
    title: 'The intersection of nitrogen nutrition and water use in plants: new paths toward improved crop productivity',
    url: 'https://academic.oup.com/jxb/article/71/15/4452/5714586',
    authors: [
      'Plett, D. C.', 'Ranathunge, K.', 'Melino, V. J.', 
      'Kuya, N.', 'Uga, Y.', 'Kronzucker, H. J.'
    ],
    publishedDate: '2020',
    category: 'Plant Science',
    relatedPostIds: [],
  },
  {
    id: 'ref7',
    title: 'How we teach computers to understand pictures | Fei-Fei Li',
    url: 'https://www.youtube.com/watch?v=40riCqvRoMs',
    authors: ['TED'],
    publishedDate: '2015, March 23',
    category: 'Computer Vision',
    relatedPostIds: [],
  },
  {
    id: 'ref8',
    title: 'Ending hunger: The role of agriculture',
    url: '#',
    authors: ['Uphaus, C.'],
    publishedDate: '2008',
    category: 'Agriculture Policy',
    relatedPostIds: [],
  },
  {
    id: 'ref9',
    title: 'Irrigation',
    url: 'https://www.britannica.com/technology/irrigation',
    authors: ['Jones, B. A.'],
    publishedDate: '2024, November 28',
    category: 'Irrigation',
    relatedPostIds: [],
  }
];

const ReferenceList: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <div className="space-y-3">
        {DUMMY_REFERENCES.map((reference) => (
          <ReferenceCard key={reference.id} reference={reference} />
        ))}
      </div>
    </div>
  );
};

export default ReferenceList;
