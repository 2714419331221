import React from 'react';
import BlogCard from './BlogCard';
import { BlogPost } from '../../types/blog';

const DUMMY_POSTS: BlogPost[] = [
  {
    id: '2',
    title: 'Plant Disease Prediction',
    excerpt: 'Explore AI-driven plant disease prediction systems.',
    sections: [
      {
        title: 'How it Works',
        content: `Computer Vision-based AI systems can quickly analyze input in the form of images of plants and other environmental conditions like weather and soil to help identify plant diseases. This process is similar to that of the creation of the Image Net Dataset. In Dr. Fei Fei Li's Ted Talk, Li describes how images are classified with certain classifications. These labeled images can be used to train an image classification model to give classifications to images based on how the plant looks or the environmental conditions. According to “Early Crop Disease Detection with AI: Strategies for Prevention” (2024), it becomes much easier for farmers to pinpoint problematic plants and treat them early to control the spread of diseases to the entire crop.

AI models can also access historical data and current environmental conditions to predict diseases, prompting farmers to take preventive measures such as using pesticides or controlling irrigation to minimize the chances of plant disease. Some platforms like Plantix and FarmWave already use similar technologies, which could see further improvement with better computer vision algorithms and data.`
      }
    ],
    inputs: [
      'Digital images',
      'Weather data',
      'Soil pH',
      'Temperature logs'
    ],
    outputs: [
      'Predictions of disease type',
      'Likelihood of future infections',
      'Suggested preventive actions'
    ],
    malfunctions: [
      'Misdiagnosis due to poor image quality or insufficient training data',
      'Over-reliance on AI without human oversight leads to unnecessary resource use (Miller)'
    ],
    imageUrl: '/images/blog/saksham.png',
    category: 'Architecture',
    date: 'Dec 14, 2024',
    author: {
      name: 'Saksham & Giorgio',
      avatar: '/images/authors/saksham.png',
      bio: 'UMD Computer Science Student'
    },
    tags: ['Disease Prediction', 'AI', 'Computer Vision', 'Machine Learning'],
  },
  {
    id: '3',
    title: 'Supply Chain Optimization in Agriculture',
    excerpt: 'How AI enhances agricultural supply chains through data-driven decision-making.',
    sections: [
      {
        title: 'How It Works and Benefits',
        content: `Artificial Intelligence (AI) is changing the game for agricultural supply chains by analyzing data from sources like weather patterns, soil conditions, and market demand. This data helps farmers and agribusinesses make better decisions. By using AI-driven forecasting models, they can predict crop yields, understand climate impacts, and prepare for supply chain disruptions, leading to stronger and more efficient operations.

AI is being used in crop planning, resource management, and logistics. For instance, AI can suggest which high-value crops to plant based on soil quality and rainfall history, helping farmers make the most of their land. It can also optimize transportation routes and schedules, ensuring deliveries are on time and reducing waste.`
      }
    ],
    inputs: [
      'Weather Data: Gathered through IoT devices, satellite imaging, and government databases to monitor conditions affecting crop growth.',
      'Soil Health Metrics: Measured using sensors and remote sensing technologies to check soil quality and decide which crops will thrive.',
      'Market Demand Forecasts: Analyzed with AI to predict consumer demand and adjust production plans.',
      'Historical Crop Performance Data: Used to train AI models for predicting yields and selecting crops.'
    ],
    outputs: [
      'Optimized Crop Selection and Planting Schedules: AI suggests the best crops to plant and the right times for planting based on the data.',
      'Efficient Resource Allocation Plans: AI helps farmers figure out how to use resources like water and fertilizers wisely, making sure nothing goes to waste.',
      'Smarter Logistics and Distribution Strategies: AI improves transportation planning to make timely deliveries and cut down on waste.',
      'Better Yield Predictions: AI provides accurate crop yield forecasts to help with planning and decision-making.'
    ],
    malfunctions: [
      'Inaccurate Predictions: If the data quality is poor or there isn’t enough training data, AI predictions can go wrong.',
      'Over-Reliance on AI: Relying too much on AI without human checks can lead to bad decisions, so human judgment is still crucial.',
      'Data Privacy Concerns: Collecting and using farm data raises privacy and security issues that need careful handling.'
    ],
    imageUrl: '/images/blog/hassen.jpeg',
    category: 'Supply Chain',
    date: 'Dec 14, 2024',
    author: {
      name: 'Hassen & Diyan',
      avatar: '/images/authors/hassen.jpg',
      bio: 'UMD Information Science Student',
    },
    tags: ['Supply Chain', 'Optimization', 'Agriculture', 'AI'],
  },
  {
    id: '4',
    title: 'Irrigation and Plant Nourishment Systems',
    excerpt: 'Analysis of modern irrigation systems and plant nourishment strategies in smart agriculture.',
    sections: [
      {
        title: 'Overview',
        content: `Irrigation is the practice of watering land to grow crops, plants, and lawns (Jones, 2024). Mankind has used these agricultural practices for thousands of years and has positively impacted all societies by improving efficiency in agriculture until now in the modern era. With this in mind, artificial intelligence (AI) has the potential to further improve agriculture in numerous ways. One way it can affect agriculture is by using AI algorithms to analyze historical data, weather patterns, and soil conditions to better predict crop yields and advise farmers on optimal planting and harvesting schedules. Technology today is more sophisticated compared to centuries ago in which the efficiency of watering and growing crops has increased including the reduction in time it would take to do this. This can solve the big problem of world hunger wherein increases food supply and lowers food prices (Uphaus).`
      },
      {
        title: 'Nourishment',
        content: `Nourishment is the key outcome of agriculture, and agriculture is a foundational aspect of nutrition. Irrigation contributes to nourishment where it ensures that crops and soil receive substantial amounts of water to support their growth and development. One instance of irrigation contributing to nutrition is when soil nutrients are dissolved by water which then creates a solution that can be absorbed by plant roots. Without sufficient amounts of water, both plants and crops cannot take up the essential nutrients they need for growth which include the elements nitrogen, phosphorus, and potassium (2024). With AI in the picture, it will improve nutrition levels within plants and crops leading to people’s nutritional needs being met. This will be accomplished by applying Internet of Things (IoT) sensors which will monitor designated water delivery to specific root zones at every single hour of each day. AI will store more information of track records as to what species of plants and certain types of crops that must be watered in specific amounts alongside changing soil and fertilizers to maximize nourishment in them as to provide better nutrition. Thus, when people consume some food plants and crops, their overall health will improve.`
      }
    ],
    inputs: [
      'Data inputs: Environment aspects, specific crops, geospatial, and real-time data.',
      'Technological inputs: AI models, algorithms, and hardware components.',
      'Human knowledge: Efficient crop production methods, seed sowing techniques, and fertilizer usage.'
    ],
    outputs: [
      'Resource usage suggestions: Correct amounts of water and fertilizers.',
      'Status updates: Resource usage status based on crop type, location, and satellite data.',
      'Growth analysis: Monitoring plant growth for resource optimization.'
    ],
    malfunctions: [
      'Incomplete records: Crop types and water usage data gaps.',
      'Connectivity issues: Internet problems hindering data updates.',
      'Misinterpretations: Environmental condition misreadings.',
      'Algorithmic errors: AI model inaccuracies.',
      'Equipment failures: IoT sensor malfunctions.'
    ],
    imageUrl: '/images/blog/marco.png',
    category: 'Irrigation',
    date: 'Dec 14, 2024',
    author: {
      name: 'Marco',
      avatar: '/images/authors/marco.jpg',
      bio: 'UMD Information Science Student',
    },
    tags: ['Irrigation', 'Plant Nutrition', 'Smart Farming'],
  },
  {
    id: '5',
    title: 'Harvest Prediction',
    excerpt: 'AI-based systems for forecasting harvest yields using diverse data sources.',
    sections: [
      {
        title: 'How it will work and benefits/examples/facts to justify it',
        content: `AI-based harvest prediction systems can analyze various data sources such as weather patterns, soil conditions, crop type, and historical yield data, to forecast the quantity and quality of the harvest. By integrating machine learning algorithms, farmers can gain insights into the expected yield, helping them plan logistics, storage, and market strategies effectively. These systems also enable early detection of potential yield-reducing factors, such as adverse weather events, pests, or nutrient deficiencies, allowing farmers to take timely action to maximize productivity. For instance, studies show that combining AI with satellite data has improved prediction accuracy by up to 20%, significantly benefiting farmers in optimizing resources.`
      }
    ],
    inputs: [
      'Satellite imagery',
      'Soil nutrient levels',
      'Weather forecasts',
      'Crop type',
      'Farming practices'
    ],
    outputs: [
      'Forecasted harvest quantity',
      'Quality metrics',
      'Resource optimization strategies'
    ],
    malfunctions: [
      'Inaccurate predictions due to sudden, extreme weather events not accounted for in the model.',
      'Errors caused by incomplete or outdated data leading to suboptimal farming decisions.'
    ],
    imageUrl: '/images/blog/charles.png',
    category: 'Prediction',
    date: 'Dec 14, 2024',
    author: {
      name: 'Charles',
      avatar: '/images/authors/charles.jpg',
      bio: 'UMD Computer Science Student',
    },
    tags: ['Harvest Prediction', 'Machine Learning', 'Agriculture'],
  },
];

const BlogList: React.FC = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {DUMMY_POSTS.map((post) => (
        <BlogCard key={post.id} post={post} />
      ))}
    </div>
  );
};

export default BlogList;