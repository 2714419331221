import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="bg-green-600 text-white shadow-lg">
      <nav className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <div>
            <span className="text-2xl font-bold">
              ADS in Agriculture
            </span>
            <span className="text-sm text-gray-400 block">
              Automated Decision Making Systems
            </span>
          </div>
          <div className="space-x-6">
            <a href="#home" className="hover:text-green-200">Home</a>
            <a href="#articles" className="hover:text-green-200">Articles</a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header; 