// frontend/src/pages/Home.tsx
import React from 'react';
import BlogList from '../components/blog/BlogList';
import ArticleView from '../components/blog/ArticleView';
import { FaArrowRight, FaBook } from 'react-icons/fa';
import { ArticleProvider } from '../components/blog/ArticleContext';
import ReferenceList from '../components/references/ReferenceList';

const Home: React.FC = () => {
  return (
    <ArticleProvider>
      <div className="min-h-screen bg-gradient-to-b from-slate-50 to-white">
        {/* Hero Section */}
        <section className="relative overflow-hidden bg-gradient-to-b from-gray-100 to-white text-gray-900 py-24">
          <div className="container mx-auto px-6 md:px-8 lg:px-10 flex flex-col md:flex-row md:items-center">
            <div className="md:w-1/2 md:pr-12">
              <h1 className="text-4xl md:text-5xl font-semibold tracking-tight leading-tight mb-6">
                The Future of Agriculture with AI
              </h1>
              <p className="text-lg md:text-xl text-gray-600 leading-relaxed mb-6">
                Agriculture has always been an essential part of human civilization, and with a growing population, food security is becoming an even more important issue that needs to be addressed. However, traditional farming struggles with agricultural challenges like unpredictable weather and disasters, pests, market fluctuations, and resource utilization. This is where Artificial Intelligence comes into play with creative insights to revolutionize farming practices for modern farmers.
              </p>
              <p className="text-lg md:text-xl text-gray-600 leading-relaxed mb-8">
                Imagine a world where farmers can predict not only the health of their crops but also which crops will be in the highest demand in the next season. What if they could optimize resources like water, fertilizer, and pesticides to minimize costs and environmental impact? How can they plan their harvest and storage better to maximize profit while reducing food waste? These are some of the critical questions AI in agriculture seeks to answer.
              </p>
              <div className="flex flex-wrap gap-4">
                <a
                  href="#articles"
                  className="inline-flex items-center border border-gray-300 px-5 py-3 rounded-md font-medium text-gray-700 hover:bg-gray-100 transition-colors"
                >
                  Explore Articles
                  <FaArrowRight className="ml-2 text-gray-500 group-hover:translate-x-1 transition-transform" />
                </a>
                <a
                  href="#references"
                  className="inline-flex items-center border border-gray-300 px-5 py-3 rounded-md font-medium text-gray-700 hover:bg-gray-100 transition-colors"
                >
                  View References
                  <FaBook className="ml-2 text-gray-500" />
                </a>
              </div>
            </div>
            <div className="md:w-1/2 mt-10 md:mt-0">
              <div className="relative w-full max-w-xl mx-auto">
                <img
                  src="/images/intro/intro.png"
                  alt="AI in Agriculture"
                  className="w-full h-auto rounded-md shadow-md"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Featured Articles Section */}
        <section id="articles" className="py-16 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
              Student Articles
            </h2>
            <BlogList />
            
            {/* References Section */}
            <div id="references" className="mt-20">
              <h3 className="text-2xl font-bold mb-6 text-center text-gray-800">
                References
              </h3>
              <ReferenceList />
            </div>
          </div>
        </section>

        {/* Article Modal */}
        <ArticleView />
      </div>
    </ArticleProvider>
  );
};

export default Home;