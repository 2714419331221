import React from 'react';
import { useArticle } from './ArticleContext';

const ArticleView: React.FC = () => {
  const { selectedArticle, isArticleOpen, setIsArticleOpen, setSelectedArticle } = useArticle();

  if (!isArticleOpen || !selectedArticle) return null;

  const handleClose = () => {
    setIsArticleOpen(false);
    setSelectedArticle(null);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="relative">
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          
          <img
            src={selectedArticle.imageUrl}
            alt={selectedArticle.title}
            className="w-full h-64 object-cover rounded-t-lg"
          />
          
          <div className="p-6">
            <div className="flex items-center mb-4">
              <span className="bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded">
                {selectedArticle.category}
              </span>
              <span className="text-gray-500 text-sm ml-4">{selectedArticle.date}</span>
            </div>
            
            <h1 className="text-3xl font-bold mb-4 text-gray-800">
              {selectedArticle.title}
            </h1>
            
            <div className="flex items-center mb-6">
              <img
                src={selectedArticle.author.avatar}
                alt={selectedArticle.author.name}
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <p className="font-medium text-gray-800">{selectedArticle.author.name}</p>
                {selectedArticle.author.bio && (
                  <p className="text-sm text-gray-600">{selectedArticle.author.bio}</p>
                )}
              </div>
            </div>
            
            <div className="prose max-w-none">
              {selectedArticle.sections && selectedArticle.sections.length > 0 ? (
                selectedArticle.sections.map((section) => (
                  <div key={section.title} className="mb-6">
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">
                      {section.title}
                    </h3>
                    <p className="text-gray-600 whitespace-pre-line">
                      {section.content}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-gray-600 leading-relaxed whitespace-pre-line">
                  {selectedArticle.content}
                </p>
              )}

              {selectedArticle.inputs && (
                <div className="mt-6">
                  <h3 className="text-xl font-semibold mb-2">Inputs</h3>
                  <ul className="list-disc pl-5">
                    {selectedArticle.inputs.map((input, index) => (
                      <li key={index} className="text-gray-600">{input}</li>
                    ))}
                  </ul>
                </div>
              )}

              {selectedArticle.outputs && (
                <div className="mt-6">
                  <h3 className="text-xl font-semibold mb-2">Outputs</h3>
                  <ul className="list-disc pl-5">
                    {selectedArticle.outputs.map((output, index) => (
                      <li key={index} className="text-gray-600">{output}</li>
                    ))}
                  </ul>
                </div>
              )}

              {selectedArticle.malfunctions && (
                <div className="mt-6">
                  <h3 className="text-xl font-semibold mb-2">Potential Malfunctions</h3>
                  <ul className="list-disc pl-5">
                    {selectedArticle.malfunctions.map((malfunction, index) => (
                      <li key={index} className="text-gray-600">{malfunction}</li>
                    ))}
                  </ul>
                </div>
              )}

            </div>
            
            {selectedArticle.tags && (
              <div className="mt-6 flex flex-wrap gap-2">
                {selectedArticle.tags.map((tag) => (
                  <span
                    key={tag}
                    className="bg-gray-100 text-gray-700 text-sm px-3 py-1 rounded-full"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleView; 